<template>
  <div class="home">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data () {
    return {

    }
  },
  methods: {
  },
  mounted () {
    document.title = '校招职位'
  }
}
</script>

<style scoped lang="less">
.home {
  min-height: 100vh;
}
</style>
