import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 初始化css样式
import 'normalize.css'
import '@/assets/css/index.css'

// 引入vForm
import VmFormRender from '../lib/vform/VmFormRender.umd.min.js' // 引入VFormRender组件
import '../lib/vform/VmFormRender.css'

// 引入vant UI组件
import Vant from 'vant'
import 'vant/lib/index.css'

// 左滑右滑功能
import VueTouch from 'vue-touch'
Vue.use(VueTouch, { name: 'v-touch' })
// VueTouch.config.swipe = {
//   threshold:50  //设置左右滑动的距离
// }

// import VueVideoPlayer from 'vue-video-player'
// require('vue-video-player/node_modules/video.js/dist/video-js.css')
// require('vue-video-player/src/custom-theme.css')
// Vue.use(VueVideoPlayer)

Vue.use(VmFormRender) // 注册v-form-render等组件
Vue.use(Vant)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
