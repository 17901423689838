<template>
  <div class="tab-bar">
    <van-tabbar route>
      <van-tabbar-item icon="home-o" to="/home">校招职位</van-tabbar-item>
      <van-tabbar-item icon="search" to="/alllist" >服务大厅</van-tabbar-item>
      <van-tabbar-item icon="friends-o" to="/profile">个人信息</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  }
}
</script>

<style>

</style>
