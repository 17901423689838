<template>
  <div id="app">
    <router-view />
    <tab-bar/>
  </div>
</template>

<script lang="ts">
import TabBar from '@/components/tab-bar/tab-bar.vue'

export default ({
  components: {
    TabBar
  },
  data () {
    return {

    }
  }
})
</script>

<style lang="less">
*{
  padding: 0;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
