import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    component: HomeView,
    children: [
      // 校招职位 (第一个tab)
      {
        path: '/',
        name: 'recruitment',
        component: () => import('@/views/recruitmentposition/recruitment.vue')
      },
      // 我的校招 (第四个tab)
      {
        path: '/schoolmove',
        name: 'schoolmove',
        component: () => import('@/views/schoolmove/schoolmove.vue')
      }
    ]
  },
  // 服务大厅 (第二个tab)
  {
    path: '/alllist',
    name: 'alllist',
    component: () => import('@/views/secondtab/alllist.vue')
  },
  //   TODO 测试demo 后期直接删除
  {
    path: '/vformDemo',
    name: 'vformDemo',
    component: () => import('@/views/vformDemo/index.vue')
  },
  // 搜索页面
  {
    path: '/searchpage',
    name: 'searchpage',
    component: () => import('@/views/recruitmentposition/searchpage.vue')
  },
  // 职位详情
  {
    path: '/positiondetail',
    name: 'positiondetail',
    component: () => import('@/views/recruitmentposition/positiondetail.vue')
  },
  // 企业详情
  {
    path: '/enterprisedetail',
    name: 'enterprisedetail',
    component: () => import('@/views/recruitmentposition/enterprisedetail.vue')
  },
  // 双选会企业
  {
    path: '/airdoubleenterprise',
    name: 'airdoubleenterprise',
    component: () => import('@/views/thirdlytab/airdoubleenterprise.vue')
  },
  // 宣讲会详情
  {
    path: '/meetingdetail',
    name: 'meetingdetail',
    component: () => import('@/views/secondtab/meetingdetail.vue')
  },
  // 线下宣讲会列表
  {
    path: '/airpreachonline',
    name: 'airpreachonline',
    component: () => import('@/views/secondtabonline/airpreach.vue')
  },
  // 线上宣讲会列表
  {
    path: '/airpreach',
    name: 'airpreach',
    component: () => import('@/views/secondtab/airpreach.vue')
  },
  // 线上双选会详情
  {
    path: '/meetingdetailonline',
    name: 'meetingdetailonline',
    component: () => import('@/views/secondtabonline/meetingdetail.vue')
  },
  // 双选会企业的职位列表
  {
    path: '/enterprisepositionlist',
    name: 'enterprisepositionlist',
    component: () => import('@/views/thirdlytab/enterprisepositionlist.vue')
  },
  // 线上双选会
  {
    path: '/airdouble',
    name: 'airdouble',
    component: () => import('@/views/thirdlytab/airdouble.vue')
  },
  // 线下双选会列表
  {
    path: '/offlineairdouble',
    name: 'offlineairdouble',
    component: () => import('@/views/thirdlytab/offlineairdouble.vue')
  },
  // 线下双选会详情
  {
    path: '/offlineairdoubleenterprise',
    name: 'offlineairdoubleenterprise',
    component: () => import('@/views/thirdlytab/offlineairdoubleenterprise.vue')
  },
  // 添加个人信息
  {
    path: '/informationEdit',
    name: 'informationEdit',
    component: () => import('@/views/schoolmove/informationEdit.vue')
  },
  // 添加个人优势
  {
    path: '/personadvantage',
    name: 'personadvantage',
    component: () => import('@/views/schoolmove/personadvantage.vue')
  },
  // 添加求职意向
  {
    path: '/addjobintention',
    name: 'addjobintention',
    component: () => import('@/views/schoolmove/addjobintention.vue')
  },
  // 添加教育经历
  {
    path: '/addeducation',
    name: 'addeducation',
    component: () => import('@/views/schoolmove/addeducation.vue')
  },
  // 选择学校
  {
    path: '/selectschool',
    name: 'selectschool',
    component: () => import('@/views/schoolmove/selectschool.vue')
  },
  // 添加荣誉奖励
  {
    path: '/honorsawards',
    name: 'honorsawards',
    component: () => import('@/views/schoolmove/honorsawards.vue')
  },
  // 添加实习经历
  {
    path: '/internexperience',
    name: 'internexperience',
    component: () => import('@/views/schoolmove/internexperience.vue')
  },
  // 添加校园经历
  {
    path: '/schoolexperience',
    name: 'schoolexperience',
    component: () => import('@/views/schoolmove/schoolexperience.vue')
  },
  // 已投简历跳转
  {
    path: '/resumeList',
    name: 'resumeList',
    component: () => import('@/views/schoolmove/resumeList.vue')
  },
  // 待面试跳转
  {
    path: '/interviewList',
    name: 'interviewList',
    component: () => import('@/views/schoolmove/interviewList.vue')
  },
  // 收藏跳转
  {
    path: '/collectList',
    name: 'collectList',
    component: () => import('@/views/schoolmove/collectList.vue')
  },
  // 选择期望职位
  {
    path: '/selectposition',
    name: 'selectposition',
    component: () => import('@/views/schoolmove/selectposition.vue')
  },
  // 选择专业
  {
    path: '/selectmajor',
    name: 'selectmajor',
    component: () => import('@/views/schoolmove/selectmajor.vue')
  },
  // 添加实习企业名称
  {
    path: '/selectenterprise',
    name: 'selectenterprise',
    component: () => import('@/views/schoolmove/selectenterprise.vue')
  },
  // 添加实习工作内容
  {
    path: '/jobcontent',
    name: 'jobcontent',
    component: () => import('@/views/schoolmove/jobcontent.vue')
  },
  // 添加校园经历工作内容
  {
    path: '/schoolcontent',
    name: 'schoolcontent',
    component: () => import('@/views/schoolmove/schoolcontent.vue')
  },
  // 添加技能证书
  {
    path: '/skillcertificate',
    name: 'skillcertificate',
    component: () => import('@/views/schoolmove/skillcertificate.vue')
  },
  // 就业课程列表
  {
    path: '/lifelonglist',
    name: 'lifelonglist',
    component: () => import('@/views/lifelonglearning/lifelonglist.vue')
  },
  // 就业课程详情
  {
    path: '/lifelongdetail',
    name: 'lifelongdetail',
    component: () => import('@/views/lifelonglearning/lifelongdetail.vue')
  },
  // 我的就业推荐表
  {
    path: '/myjobrecommend',
    name: 'myjobrecommend',
    component: () => import('@/views/jobrecommend/myjobrecommend.vue')
  },
  // 审核记录
  {
    path: '/approvedmemo',
    name: 'approvedmemo',
    component: () => import('@/views/jobrecommend/approvedmemo.vue')
  },
  // 就业推荐表申领
  {
    path: '/jobrecommendform',
    name: 'jobrecommendform',
    component: () => import('@/views/jobrecommend/jobrecommendform.vue')
  },
  // 奖惩情况
  {
    path: '/rewardsandpunishment',
    name: 'rewardsandpunishment',
    component: () => import('@/views/jobrecommend/rewardsandpunishment.vue')
  },
  // 社会实践
  {
    path: '/socialpractice',
    name: 'socialpractice',
    component: () => import('@/views/jobrecommend/socialpractice.vue')
  },
  // 我的就业协议书
  {
    path: '/myjobagreement',
    name: 'myjobagreement',
    component: () => import('@/views/jobagreement/myjobagreement.vue')
  },
  // 我的就业协议书审核
  {
    path: '/approveagreement',
    name: 'approveagreement',
    component: () => import('@/views/jobagreement/approveagreement.vue')
  },
  // 我的就业协议书申领第一步
  {
    path: '/jobagreementformfirst',
    name: 'jobagreementformfirst',
    component: () => import('@/views/jobagreement/jobagreementformfirst.vue')
  },
  // 我的就业协议书申领第二步
  {
    path: '/jobagreementformsecond',
    name: 'jobagreementformsecond',
    component: () => import('@/views/jobagreement/jobagreementformsecond.vue')
  },
  // 我的就业协议书申领第三步
  {
    path: '/jobagreementformthird',
    name: 'jobagreementformthird',
    component: () => import('@/views/jobagreement/jobagreementformthird.vue')
  },
  // 获取企业名称
  {
    path: '/enterprisename',
    name: 'enterprisename',
    component: () => import('@/views/jobagreement/enterprisename.vue')
  },
  // 我的就业信息填报
  {
    path: '/myjobmessage',
    name: 'myjobmessage',
    component: () => import('@/views/jobmessage/myjobmessage.vue')
  },
  // 我的就业信息填报审核页
  {
    path: '/jobmesaudit',
    name: 'jobmesaudit',
    component: () => import('@/views/jobmessage/jobmesaudit.vue')
  },
  // 我的就业信息填报-确定
  {
    path: '/myjobmessagetrue',
    name: 'myjobmessagetrue',
    component: () => import('@/views/jobmessage/myjobmessagetrue.vue')
  },
  // 我的就业信息填报表单第一步
  {
    path: '/myjobformfirst',
    name: 'myjobformfirst',
    component: () => import('@/views/jobmessage/myjobformfirst.vue')
  },
  // 我的就业信息填报表单第二步
  {
    path: '/myjobformsecond',
    name: 'myjobformsecond',
    component: () => import('@/views/jobmessage/myjobformsecond.vue')
  },
  // 我的就业信息填报表单第三步
  {
    path: '/myjobformthird',
    name: 'myjobformthird',
    component: () => import('@/views/jobmessage/myjobformthird.vue')
  },
  // 我的就业信息填报选择企业
  {
    path: '/myjobformenterprise',
    name: 'myjobformenterprise',
    component: () => import('@/views/jobmessage/enterprisename.vue')
  },
  // 职位辅导预约列表
  {
    path: '/coachorderlist',
    name: 'coachorderlist',
    component: () => import('@/views/positioncoach/coachorderlist.vue')
  },
  // 咨询师详情
  {
    path: '/counselordetail',
    name: 'counselordetail',
    component: () => import('@/views/positioncoach/counselordetail.vue')
  },
  // 职位辅导预约记录
  {
    path: '/advancerecord',
    name: 'advancerecord',
    component: () => import('@/views/positioncoach/advancerecord.vue')
  },
  // 职位辅导评价
  {
    path: '/evaluate',
    name: 'evaluate',
    component: () => import('@/views/positioncoach/evaluate.vue')
  },
  // 个人基本信息
  {
    path: '/personalinformation',
    name: 'personalinformation',
    component: () => import('@/views/positioncoach/personalinformation.vue')
  },
  // 就业活动列表
  {
    path: '/jobactivelist',
    name: 'jobactivelist',
    component: () => import('@/views/jobactive/jobactivelist.vue')
  },
  // 就业活动详情
  {
    path: '/jobactivedetail',
    name: 'jobactivedetail',
    component: () => import('@/views/jobactive/jobactivedetail.vue')
  },
  // 报名人数
  {
    path: '/applynumber',
    name: 'applynumber',
    component: () => import('@/views/jobactive/applynumber.vue')
  },

  // 登录页
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login.vue')
  },
  // 个人信息
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/profile/profile.vue')
  },
  // 职位列表页(已投、收藏、看过)
  {
    path: '/position',
    name: 'position',
    component: () => import('@/views/profile/position/position.vue')
  },
  // 喜欢的企业
  {
    path: '/favorFirm',
    name: 'favorFirm',
    component: () => import('@/views/profile/favorFirm/favorFirm.vue')
  },
  // 在线简历
  {
    path: '/resume',
    name: 'resume',
    component: () => import('@/views/profile/resume/resume.vue')
  },
  // 个人信息编辑
  {
    path: '/info',
    name: 'info',
    component: () => import('@/views/profile/info/info.vue')
  },
  // 求职意向
  {
    path: '/intent',
    name: 'intent',
    component: () => import('@/views/profile/resume/intent.vue')
  },
  // 教育经历
  {
    path: '/eduExp',
    name: 'eduExp',
    component: () => import('@/views/profile/resume/edu-experience.vue')
  },
  // 实习经历
  {
    path: '/internExp',
    name: 'internExp',
    component: () => import('@/views/profile/resume/intern-experience.vue')
  },
  // 项目经历
  {
    path: '/projectExp',
    name: 'projectExp',
    component: () => import('@/views/profile/resume/project-experience.vue')
  },
  // 项目业绩
  {
    path: '/projectcontent',
    name: 'projectcontent',
    component: () => import('@/views/schoolmove/projectcontent.vue')
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
